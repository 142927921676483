import React, { Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
const PromoPage = React.lazy(() => import("../components/PromoPage"));
const Footer = React.lazy(() => import("../components/Footer"));
const NavbarPrincipal = React.lazy(() =>
  import("../components/NavbarPrincipal")
);

const Body = React.lazy(() => import("../components/Body"));

export default function HomePage() {
  return (
    <div className="flex flex-col">
      {/* <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <NavbarPrincipal />
          </Suspense>
        </ErrorBoundary>
      </div> */}
      {/* <div className="mt-7 sm:mt-24"> */}
      {/* <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Body />
          </Suspense>
        </ErrorBoundary>
      </div> */}
      <div className="">
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <PromoPage />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Footer />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}
